<template>
  <transition name="slide">
    <CCard>
      <CCardBody>
        <h4>
            <b>Templates</b>
          </h4>
          <br>
        <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>
        <CDataTable
          :items="items"
          :fields="fields"
          :items-per-page="50"
          hover
          pagination
        >
          <template #name="{ item }">
            <td>
              <strong>{{ item.name }}</strong>
            </td>
          </template>

          <template #EDIT="{ item }">
            <td style="width: 15%">
              <CButton color="primary" @click="edit(item.code)"
                ><CIcon name="cil-pencil" /> Edit</CButton
              >
            </td>
          </template>
          <template #DELETE="{ item }">
            <td style="width: 15%">
              <CButton
                color="danger"
                @click="
                  warningModal = true;
                  policy = item;
                "
                ><CIcon name="cil-trash" /> Delete</CButton
              >
            </td>
          </template>
        </CDataTable>

        <CModal
          v-model="policy.id"
          title="Are you sure to delete selected policy?"
          color="danger"
          :show.sync="warningModal"
          @update:show="closeModal"
        >
          <CRow>
            <CCol col="12">
              <CInput
                label="Key"
                type="input"
                v-model="policy.key"
                disabled
              />
            </CCol>
          </CRow>
        </CModal>
      </CCardBody>
    </CCard>
  </transition>
</template>

<script>
import axios from "axios";

export default {
  name: "Policies",
  data: () => {
    return {
      token: localStorage.getItem("api_token"),
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      policy: {
        id: "00000000-0000-0000-0000-000000000000",
        key: null,
        partnerName:null,
        contentTranslations: [],
      },

      warningModal: false,
      items: [{code: "booking-confirmation", name: "Booking Confirmation"}],
      fields: ["name", "EDIT"],
      // Alert
      message: null,
      alertType: "danger",
    };
  },
  computed: {},
  methods: {
    edit(code) {
      const link = `templates/edit/${code}`;
      this.$router.push({ path: link });
    },
  },
  mounted: function () {

  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
